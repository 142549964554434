/* eslint-disable no-undef */
import { HOME, PATHS, STATE, STORAGE_KEY, TV_MODELS } from "../../../../utils/constants";
import { dispatchNotAvailableEvent, EVENTS } from "../../../../utils/eventsConst";
import { Logger } from "../../../../utils/logger";
import { prepareDeviceKeyMap, resetCredentialsOnPowerOff } from "../../../../utils/utils";
import { Media } from "../../media";
import { keyMap } from "./keyMap";
import { STB as STB_base } from "../../stb";

export const STB = {
    model: TV_MODELS.TIZEN,
    //videoOnBack: true,
    ref: ``,
    isTizen: true,
    needCanvas: true,
    hideSubtitlesOnMenu: true,
    forceMovieLanguage: true,
    tvName: ``,
    init() {
        const communicationiFrame = document.createElement("iframe");
        communicationiFrame.id = "tizenCommunication";
        communicationiFrame.style.visibility = "hidden";
        communicationiFrame.src = "file:///tizenHAcomm.html";
        document.body.appendChild(communicationiFrame);

        this.keyMap = prepareDeviceKeyMap(keyMap);
        samsung.init();

        this.ip = webapis.network.getIp();
        //get model and Firmware
        localStorage.setItem("tvModel", samsung.getModel());
        localStorage.setItem("firmwareVersion", samsung.getFirmware());

        if (!samsung?.TEP) {
            // Replay for [Tizen - H.Browser]
            try {
                b2bapis.b2bpower.setPowerStateChangeListener(this.onPowerChange);
            } catch (e) {
                console.debug(`ERROR setting power state listener ${e}`);
                b2bapis.b2bpower.unsetPowerStateChangeListener();
                b2bapis.b2bpower.setPowerStateChangeListener(this.onPowerChange);
            }
        }

        samsung.deleteLocalSubtitles();
        samsung.hideSource();
        samsung.launchSource("TV", 1, function () {});

        document.addEventListener("visibilitychange", function () {
            if (document.hidden) {
                console.log("INTERFACE VISIBILITY CHANGE TO HIDDEN");
                STB._resetCredentialsOnPowerOff();
            } else {
                console.log("INTERFACE VISIBILITY CHANGE TO VISIBLE");
                if (sessionStorage.getItem("onCCView")) {
                    sessionStorage.removeItem("onCCView");
                    let e = new CustomEvent(EVENTS.type.NAVIGATION, {
                        detail: { layout: HOME },
                    });
                    document.dispatchEvent(e);
                }
                if (samsung.TEP) {
                    // Replay for [Tizen - TEP]
                    STB.onPowerChange();
                }
            }
        });
    },
    ensureZafiroInterface() {
        if (document.visibilityState == "hidden") {
            function onListInstalledApps(applications) {
                for (var i = 0; i < applications.length; i++) {
                    if (applications[i].name === "zafiro") {
                        Logger.remoteLog("Ensure zafiro interface");
                        tizen.application.launch(applications[i].id, null);
                    }
                }
            }
            tizen.application.getAppsInfo(onListInstalledApps);
        }
    },

    onPowerChange(data) {
        console.log(`[PowerStateChangedCallback] progress :${JSON.stringify(data)} changed`);
        let eventType = null;
        if (data && data.data.toLowerCase().trim() == "standby") {
            eventType = EVENTS.POWER.OFF;
        } else {
            //Media.ensureVideoSize();
            STB_base.actionsOnPowerOn();
            eventType = EVENTS.POWER.ON;
        }
        let e = new CustomEvent(EVENTS.type.POWER, {
            detail: eventType,
        });
        document.dispatchEvent(e);
    },
    getMac(onSuccess) {
        this.mac = samsung.getMacAddress();
        this.ref = `${this.model}-${this.mac}`;
        if (onSuccess) {
            onSuccess();
        }
    },
    powerOn() {
        samsung.setPowerOn();
    },
    powerOff() {
        samsung.setPowerOff();
    },
    updatePowerState() {
        let status = samsung.getPowerState();
        localStorage.setItem(
            STORAGE_KEY.POWER_STATE,
            status.toLowerCase().trim() == STATE.NORMAL ? STATE.ONLINE : STATE.STANDBY,
        );
    },
    getDeviceStatus() {
        let status = samsung.getPowerState();
        return status.toLowerCase().trim() == STATE.NORMAL ? STATE.ONLINE : STATE.STANDBY;
    },
    _gotoOfflineMode() {
        document.location.href = "file:///js/ha/ha.html";
    },
    setVolume(volume) {
        samsung.setVolume(volume);
    },
    changeToInput(input) {
        Media.setVideoSize(0, 0, 100, 100);
        var successCB = function (source, type) {
            tizen.application.launch("org.tizen.tv-viewer", null); // launch HDMI window forcefully
            //            sessionStorage.setItem("outOfInterface", true);
        };
        samsung.launchSource("HDMI", input, successCB);
    },
    backToInterface() {
        samsung.hideSource();
        sessionStorage.setItem("outOfInterface", false);
        samsung.launchSource("TV", 1, () => {});
        document.body.style.visibility = "hidden";
        this.reload();
    },
    reload() {
        document.location.href = PATHS.TIZEN_ROOT;
    },
    saveDataForHA(localKey, dataToStore) {
        //send message to iframe
        var win = document.getElementById("tizenCommunication").contentWindow;
        if (win) {
            win.postMessage(JSON.stringify({ key: localKey, data: dataToStore }), "*");
        }
    },
    changeTVName(name) {
        this.tvName = "Samsung " + name;
        webapis.network.setTVName(this.tvName);
    },
    resetCredentials() {
        console.log("die template");
    },
    _resetCredentialsOnPowerOff() {
        resetCredentialsOnPowerOff();
    },
    createAccesToken() {
        if (!samsung?.TEP) {
            return;
        }
        const urlServerSaveDataForHA = "http://samsung.local:2222/tep/ipControl/createAccessToken.php";

        // Send data to server to be saved
        fetch(urlServerSaveDataForHA, {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
            },
            body: "mac=" + this.mac + "&ip=" + this.ip,
        });
    },
    _launchApp(appName) {
        switch (appName) {
            case "Netflix":
            case "NETFLIX":
                tizen.application.launch("org.tizen.netflix-app", null);
                break;
            default:
                dispatchNotAvailableEvent();
                break;
        }
    },
    noBlueToothApp() {
        // for models under AU8000 or TEP models need to launch bluetooth application to pair devices
        if (this.isTizen && localStorage.getItem("tvModel").indexOf("690") > -1) {
            return false;
        }
        return true;
    },
    launchBluetooth() {
        samsung.app.openBTMusicPlayer();
    },
};
