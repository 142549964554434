import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    getChannelData,
    getCountryNameFromIso,
    getLanguageNameFromIso,
    removeChannelToRestore,
    setVideoOnBackground,
    setVideoOnForeground,
} from "../../utils/utils";
import VerticalCenter from "../../components/common/verticalCenter";
import { Texts } from "../../utils/texts";
import TvGroups from "./tvGroups";
import { Media } from "../../hooks/apis/media";
import { STB } from "../../hooks/apis/stb";
import { setVideoPos } from "../../actions/uiActions";
import { eventHandler } from "../../utils/eventHandler";
import { KEYS } from "../../utils/keys";
import Limbo from "../../components/screen/limbo";
import focus from "../../utils/focus";
import ChannelDisplay from "./channelDisplay";
import { CHANNELS } from "../../utils/constants";
import ChannelInfo from "./channelInfo";
import RadioChannelDisplay from "./radioChannelDisplay";
import { setNumberPress } from "../../actions/eventsActions";
import { SESSION } from "../../utils/session";
import { addFavourite } from "../../actions/statusActions";
import { EVENTS } from "../../utils/eventsConst";

const Tv = () => {
    const dispatch = useDispatch();

    const playingChannelTimeout = useRef();
    const GROUP_FILTERS = useRef();

    const guestCountry = useSelector(
        (state) => state.status.locationData.guestInfo.country || state.status.locationData.projectCountryRef,
    );
    const texts = useSelector((state) => state.ui.texts);
    const reducerMediaEvent = useSelector((state) => state.events.mediaEvent);
    const numberPress = useSelector((state) => state.events.numberPress);
    const popup = useSelector((state) => state.ui.popup);

    const [loadingChannels, setLoadingChannels] = useState(true);
    const [groups, setGroups] = useState();
    const [playingChannel, setPlayingChannel] = useState();
    const [previousChannel, setPreviousChannel] = useState();
    const [tvState, setTvState] = useState(CHANNELS.TV_STATES.CHANNEL_LIST);
    const [filter, setFilter] = useState(CHANNELS.FILTERS.ALL);
    const [showContinue, setShowContinue] = useState(false);
    const [mediaError, setMediaError] = useState(false);

    const projectLanguageCode = SESSION.projectDefaultLang;

    const langCode = localStorage.getItem("lang") || projectLanguageCode;

    let tvStateRef = useRef();
    tvStateRef.current = tvState;

    useEffect(() => {
        const onSuccess = (channels) => {
            parseChannelsResponse(channels);
        };
        getChannelData(onSuccess, { guestCountry, texts, projectLanguageCode });

        if (SESSION.isTVOnCheckout()) {
            //load favourites from localstorage
            let favouriteIds = localStorage.getItem("favOnCheckout");
            if (favouriteIds) {
                favouriteIds = JSON.parse(favouriteIds);
            }
            favouriteIds && favouriteIds.map((id) => dispatch(addFavourite(id)));
        }

        setVideoOnBackground();
        document.getElementById("background")?.classList.add("hidden");

        return () => {
            document.getElementById("background")?.classList.remove("hidden");
            dispatch(setVideoPos(null));
            setVideoOnForeground();
            removeChannelToRestore();
            Media.disableSubtitles();
            eventHandler.lastSubtitleId = null;
            // Ensure media kill on exit tv layout
            setTimeout(() => {
                Media.kill();
            }, 1);
        };
    }, []);

    useEffect(() => {
        if (tvState === CHANNELS.TV_STATES.LIMBO && !popup) {
            focus.value.replace("tv-limbo");
            //restore subtitle
            if (STB.hideSubtitlesOnMenu && eventHandler.lastSubtitleId != null) {
                Media.changeSubtitle(eventHandler.lastSubtitleId);
            }
        } else if (tvState === CHANNELS.TV_STATES.CHANNEL_LIST && eventHandler.lastSubtitleId != null) {
            Media.disableSubtitles();
        }
        tvState && tvState !== CHANNELS.TV_STATES.CHANNEL_LIST && setShowContinue(true);
    }, [tvState]);

    useEffect(() => {
        clearTimeout(playingChannelTimeout.current);
        if (playingChannel && previousChannel?.id !== playingChannel?.id) {
            Media.disableSubtitles();
            eventHandler.lastSubtitleId = null;
            playingChannelTimeout.current = setTimeout(function () {
                setMediaError(null);
                Media.playChannel(playingChannel);
                setPreviousChannel(playingChannel);
            }, 200);
            setTimeout(function () {
                Media.ensureVideoSize();
            }, 500);

            // if only one channel in the grid => hide grid on load
            const singleChannel =
                groups.find((group) => group.id === CHANNELS.FILTERS.ALL_CHANNELS).items?.length === 1;
            singleChannel && setTvState(CHANNELS.TV_STATES.CHANNEL_DISPLAY);
        }
        playingChannel && showVideoPreview();
    }, [playingChannel]);

    useEffect(() => {
        if (
            reducerMediaEvent?.code === EVENTS.MEDIA.PLAY_ERROR ||
            reducerMediaEvent?.code === EVENTS.MEDIA.PLAY_SUCCESS
        ) {
            setMediaError(reducerMediaEvent?.code === EVENTS.MEDIA.PLAY_ERROR);
        }
    }, [reducerMediaEvent]);

    useEffect(() => {
        if (numberPress) {
            setTvState(CHANNELS.TV_STATES.CHANNEL_DISPLAY);
        }
    }, [numberPress]);

    const parseChannelsResponse = (data) => {
        setLoadingChannels(false);
        if (!data) {
            return;
        }
        let channels = JSON.parse(data.channels);
        setGroups(channels?.categories);
        CHANNELS.logoPath = {
            cloudURL: data.cloudURL,
            pRef: data.projectRef,
        };
        GROUP_FILTERS.current = [
            { id: CHANNELS.FILTERS.ALL, name: Texts.capitalize(texts["all channels"]) },
            { id: CHANNELS.FILTERS.MY_LIST, name: texts["my list"] },
            channels?.categories?.find((group) => group.id === CHANNELS.FILTERS.LANGUAGE) && {
                id: CHANNELS.FILTERS.LANGUAGE,
                name: Texts.capitalize(texts["language-channels"]).replace(
                    "{{data}}",
                    getLanguageNameFromIso(langCode),
                ),
            },
            guestCountry &&
                channels?.categories?.find((group) => group.id === CHANNELS.FILTERS.COUNTRY) && {
                    id: CHANNELS.FILTERS.COUNTRY,
                    name: Texts.capitalize(texts["From-country"]).replace(
                        "{{data}}",
                        getCountryNameFromIso(guestCountry),
                    ),
                },
        ];
    };

    //Adjust video to preview size
    const showVideoPreview = () => {
        if (STB.videoOnBack && playingChannel?.isradio) {
            dispatch(setVideoPos({ x: 0, y: 0, width: 0.01, height: 0.01 }));
            return;
        }

        dispatch(setVideoPos("fullscreen"));
    };

    const playByChannelNum = (channelNum) => {
        let next = null;
        groups
            ?.find((group) => group.id === filter)
            ?.items.forEach((channelGroup) => {
                channelGroup?.items.forEach((channel) => {
                    if (parseInt(channel.num) === parseInt(channelNum) && !next) {
                        next = channel;
                    }
                });
            });
        if (next) {
            setPlayingChannel(next);
        }
        dispatch(setNumberPress(""));
        setTvState(CHANNELS.TV_STATES.CHANNEL_DISPLAY);
    };

    const playNext = () => {
        let next = null;
        groups
            ?.find((group) => group.id === filter)
            ?.items.forEach((channelGroup) => {
                channelGroup?.items.forEach((channel) => {
                    if (channel.num > playingChannel.num && !next) {
                        next = channel;
                    }
                });
            });
        setPlayingChannel(next || groups?.find((group) => group.id === filter)?.items?.[0]?.items?.[0]);
        setTvState(CHANNELS.TV_STATES.CHANNEL_DISPLAY);
    };
    const playPrevious = () => {
        let previous = null;
        const cGroup = groups?.find((group) => group.id === filter);
        cGroup?.items.forEach((channelGroup) => {
            channelGroup?.items.forEach((channel) => {
                if (channel.num < playingChannel.num) {
                    previous = channel;
                }
            });
        });
        const lastChannelGroup = !previous ? cGroup?.items?.[cGroup.items?.length - 1] : {};

        setPlayingChannel(previous || lastChannelGroup?.items[lastChannelGroup.items.length - 1]);
        setTvState(CHANNELS.TV_STATES.CHANNEL_DISPLAY);
    };

    const onkeydown = (e) => {
        const keyData = eventHandler.getKeyData(e);
        let preventDefault = false;

        if (keyData) {
            if (keyData.type === "numeric") {
                dispatch(setNumberPress(keyData.value));
                eventHandler.stopPropagation(e);
                return;
            }
            switch (keyData.value) {
                case KEYS.enter:
                    if (tvStateRef.current === CHANNELS.TV_STATES.LIMBO) {
                        setTvState(CHANNELS.TV_STATES.CHANNEL_INFO);
                        preventDefault = true;
                    }
                    break;

                case KEYS.back:
                    if (tvState === CHANNELS.TV_STATES.LIMBO) {
                        setTvState(CHANNELS.TV_STATES.CHANNEL_LIST);
                        preventDefault = true;
                    } else if (SESSION.isTVOnCheckout()) {
                        setTvState(CHANNELS.TV_STATES.LIMBO);
                        preventDefault = true;
                    }
                    break;

                case KEYS.up:
                case KEYS.channelUp:
                    if (tvStateRef.current === CHANNELS.TV_STATES.LIMBO) {
                        playNext();
                        preventDefault = true;
                    }
                    break;

                case KEYS.down:
                case KEYS.channelDown:
                    if (tvStateRef.current === CHANNELS.TV_STATES.LIMBO) {
                        playPrevious();
                        preventDefault = true;
                    }
                    break;

                default:
                    break;
            }

            if (tvStateRef.current === CHANNELS.TV_STATES.LIMBO && keyData.type === "arrow") {
                preventDefault = true;
            }
        }

        if (preventDefault) {
            eventHandler.stopPropagation(e);
        }
    };
    return (
        <div
            id="tv"
            style={{
                visibility: "visible",
            }}
            onKeyDown={(e) => {
                onkeydown(e);
            }}
        >
            <Limbo id="tv-limbo" noBlocker={true} />

            {tvState === CHANNELS.TV_STATES.CHANNEL_LIST ? (
                <div className={`channelList w-full block relative ${STB.isTizen && "channelList_no_gradient"}`}>
                    {groups ? (
                        <TvGroups
                            groups={groups}
                            setGroups={setGroups}
                            filter={filter}
                            setFilter={setFilter}
                            playingChannel={playingChannel}
                            setPlayingChannel={setPlayingChannel}
                            tvState={tvState}
                            setTvState={setTvState}
                            GROUP_FILTERS={GROUP_FILTERS.current}
                            showContinue={showContinue}
                        ></TvGroups>
                    ) : (
                        <VerticalCenter
                            text={Texts.capitalize(
                                loadingChannels ? `${texts["loading"]}...` : texts["no data available"],
                            ).replace("{{data}}", texts["channels"])}
                            cClass={`white text-4xl`}
                        />
                    )}
                </div>
            ) : null}

            {tvState === CHANNELS.TV_STATES.CHANNEL_DISPLAY ? (
                <ChannelDisplay
                    playingChannel={playingChannel}
                    tvState={tvStateRef.current}
                    setTvState={setTvState}
                    playNext={playNext}
                    playPrevious={playPrevious}
                    playByChannelNum={playByChannelNum}
                />
            ) : null}

            {tvState === CHANNELS.TV_STATES.CHANNEL_INFO ? (
                <ChannelInfo
                    groups={groups}
                    filter={filter}
                    setFilter={setFilter}
                    playingChannel={playingChannel}
                    tvState={tvStateRef.current}
                    setTvState={setTvState}
                    setPlayingChannel={setPlayingChannel}
                    GROUP_FILTERS={GROUP_FILTERS.current}
                />
            ) : null}

            <RadioChannelDisplay playingChannel={playingChannel} />

            {mediaError ? (
                <div
                    className="fixed text-center w-full h-full top-0 left-0 table bg-black fg-white"
                    style={{
                        zIndex: "99",
                        visibility: "visible",
                    }}
                >
                    <div className="table-cell vertical-middle ">
                        <div
                            style={{ fontSize: "3vw", opacity: tvState === CHANNELS.TV_STATES.CHANNEL_LIST ? 0.2 : 1 }}
                        >
                            {Texts.capitalize(texts["no signal"])}
                        </div>
                    </div>
                </div>
            ) : null}
        </div>
    );
};
export default Tv;
