import { useEffect, useMemo, useRef } from "preact/hooks";
import { eventHandler } from "../../utils/eventHandler";
import focus from "../../utils/focus";
import { KEYS } from "../../utils/keys";
import { getValueOf } from "../../utils/gridUtils";
import Button from "../../components/common/button";
import { Texts } from "../../utils/texts";
import { useSelector } from "react-redux";
import { isElementVisible, moveFocus } from "../../utils/movement";
import Item from "./Item";
import { figmaHeightPx2Px, figmapx2vh, figmapx2vw, vh2px } from "../../utils/utils";
import {
    addHiddenLegend,
    isLastElementOfGroup,
    removeHiddenLegend,
    renderHiddenLegendToRow,
    SCROLL_ITEMS_DIV,
    scrollItemsDown,
} from "../../utils/movies";
import { memo } from "preact/compat";

const Group = ({ group, setActiveSingleGroup, itemHeight, itemsPerRow = 8, itemDetail, forceFocus }) => {
    const texts = useSelector((state) => state.ui.texts);
    const OFFSET = figmaHeightPx2Px(itemHeight);
    useEffect(() => {
        let firstFocus = document.getElementById(SCROLL_ITEMS_DIV).firstChild.id;
        if (forceFocus) {
            firstFocus = forceFocus.elementId;
        }
        if (firstFocus) {
            focus.value.replace(firstFocus);
        }

        return () => {
            focus.value.unstack();
        };
    }, []);

    useEffect(() => {
        renderHiddenLegendToRow(group, itemsPerRow);
        if (document.activeElement.id === "btn-exit") {
            removeHiddenLegend();
        }
    }, [focus.value.current]);

    const onSingleGroupKeydown = (e) => {
        const keyData = eventHandler.getKeyData(e);
        let preventDefault = false;

        if (keyData) {
            switch (keyData.value) {
                case KEYS.back:
                    setActiveSingleGroup(null);
                    preventDefault = true;
                    break;
                case KEYS.down:
                    const nextItemDown = moveFocus(document.activeElement.id, "down");
                    if (
                        document.activeElement.id !== "btn-exit" &&
                        nextItemDown &&
                        !isElementVisible(document.getElementById(nextItemDown))
                    ) {
                        scrollItemsDown(true, OFFSET);
                    }

                    break;
                case KEYS.up:
                    if (document.activeElement.id === "btn-exit") {
                        preventDefault = true;
                    } else {
                        scrollItemsDown(false, OFFSET);
                    }
                    break;
                case KEYS.right:
                    if (isLastElementOfGroup(group)) {
                        preventDefault = true;
                    }
                    break;
                case KEYS.left:
                    const posFocus = group.items.findIndex((item) => item.focusId === focus.value.current);
                    if (posFocus % itemsPerRow === itemsPerRow - 1) {
                        // addHiddenLegend(group.items?.[posFocus]?.focusId);
                    }
                    break;
                default:
                    break;
            }
        }

        if (preventDefault) {
            eventHandler.stopPropagation(e);
        }
    };
    return useMemo(() => {
        return (
            <div
                id="singleGroup"
                className={"singleGridGroup"}
                style={{
                    paddingLeft: figmapx2vw(72),
                    backgroundColor: "black",
                }}
                onKeyDown={(e) => {
                    onSingleGroupKeydown(e);
                }}
            >
                <div
                    id="btnBack"
                    style={{
                        height: figmapx2vh(150),
                        paddingTop: figmapx2vh(40),
                    }}
                >
                    <Button
                        insideModal={true}
                        data={{
                            id: "exit",
                            icon: "tv-back-remote",
                            iconSize: "text-2xl",
                            name: Texts.capitalize(texts["back"]),
                            customClass: `py-2 px-2 w-auto text-2xl my-auto text-gray-100 rounded`,
                            customStyle: { color: "#F5F6F8" },
                            focusClass: "grid-btn-back-focus rounded",
                            onClick() {
                                setActiveSingleGroup(null);
                            },
                        }}
                    />
                </div>
                <div
                    id="groupName"
                    className={"font-700 mb-8"}
                    style={{
                        fontSize: figmapx2vw(36),
                        height: figmapx2vh(50),
                    }}
                >
                    {group.name}
                </div>
                <div
                    id={"groupItems"}
                    className={"overflow-hidden"}
                    style={{
                        height: figmapx2vh(880),
                    }}
                >
                    <div id={SCROLL_ITEMS_DIV} className={"animateMarginTop"}>
                        {group.items.map((item) => (
                            <Item element={item} singleGroup={true} detail={itemDetail} />
                        ))}
                    </div>
                </div>
            </div>
        );
    }, [group, group.items]);
};

export default memo(Group);
