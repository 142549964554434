/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from "preact/hooks";
import { useDispatch, useSelector } from "react-redux";
import { setSessionData, setTvAppOutdated, setMessagesNotShown } from "../../actions/statusActions";
import {
    displayErrorPopUp,
    renewSignage,
    setBannerOnScreen,
    setPopUp,
    setRenewBanners,
    setRenewSignage,
    setRenewTickertapes,
    setTexts,
    showLoading,
} from "../../actions/uiActions";
import {
    CCINSTRUCTIONS_SCREEN,
    HOME,
    SCREEN,
    SIGNAGE,
    STATE,
    STORAGE_KEY,
    MS_INTERVAL,
    POPUP_NAMES,
    PERMISSION,
} from "../../utils/constants";
import { getCurrentLayout, inPreview, isWelcomed } from "../../utils/utils";
import { STB } from "../apis/stb";
import Query, { resetAllQueryCache } from "../query";
import { Media } from "../apis/media";
import { isCritical, isImportant } from "../../utils/messageUtils";
import { screenUtils } from "../../utils/screenUtils";
import { SESSION } from "../../utils/session";
import { checkResetCredentialsByHour } from "../../utils/resetCredentialsAction";
import { Logger } from "../../utils/logger";
import { ROOMTV_CONTENTS } from "../../layouts/commonZones/Contents";

const Notifications = () => {
    const dispatch = useDispatch();
    const NOTIFICATION_INTERVAL = 60000; // in milliseconds
    let notifInterval = useRef();

    const installed = useSelector((state) => state.status.installed);
    const sessionData = useSelector((state) => state.status.sessionData);
    const forceStatusUpdate = useSelector((state) => state.status.forceStatusUpdate);
    const forceMQTTUpdate = useSelector((state) => state.status.forceMQTTUpdate);
    const messagesNotShown = useSelector((state) => state.status.messagesNotShown);
    const ui = useSelector((state) => state.ui);
    const popup = useSelector((state) => state.ui.popup);
    const status = useSelector((state) => state.status);
    const texts = useSelector((state) => state.ui.texts);
    const bannerOnScreen = useSelector((state) => state.ui.bannerOnScreen);
    const sessionDataRef = useRef(sessionData);
    sessionDataRef.current = sessionData;

    const statusRef = useRef();
    statusRef.current = status;
    const uiRef = useRef();
    uiRef.current = ui;
    const popupRef = useRef();
    popupRef.current = popup;
    const currentLayoutRef = useRef();
    const alarmTimeoutRef = useRef();
    const lastSignageCheckTime = useRef();

    //If not installed, not init notificacions
    useEffect(() => {
        if (installed) {
            lastSignageCheckTime.current = new Date().getTime();
            sendStatus();
            checkResetCredentialsByHour(status?.locationData?.resetCredentialsHour || null);
            setTimeout(function () {
                checkAlarms();
            }, 1000);
            notifInterval.current = setInterval(() => {
                const includeSignage = mustIncludeSignageCheck();
                if (!STB.supportMQTT || includeSignage) {
                    sendStatus({ propagateError: false, includeSignage: includeSignage });
                } else {
                    //send currentLayout
                    const currentLayout = getCurrentLayout();
                    const cLayoutInfo = composeCurrenLayoutInfo(currentLayout);
                    if (currentLayoutRef.current !== cLayoutInfo) {
                        currentLayoutRef.current = cLayoutInfo;
                        STB.sendCurrentLayoutInfo(cLayoutInfo);
                    }
                }
                // check to reset credentials
                checkResetCredentialsByHour(status?.locationData?.resetCredentialsHour || null);
                checkAlarms();
            }, NOTIFICATION_INTERVAL);
        }
    }, [installed]);
    useEffect(() => {
        if (forceStatusUpdate) {
            sendStatus({ preventCache: true });
        }
    }, [forceStatusUpdate]);

    useEffect(() => {
        if (forceMQTTUpdate) {
            let status = STB.getDeviceStatus();
            parseStatusResponse({ data: { status: JSON.parse(status) } });
            setTimeout(function () {
                checkAlarms();
            }, 1000);
        }
    }, [forceMQTTUpdate]);

    useEffect(() => {
        if (messagesNotShown && !Media.isVideoFullScreen) {
            checkMessages();
        }
    }, [Media.isVideoFullScreen]);

    //Shown urgent messages
    useEffect(() => {
        if (!sessionData?.messages || !hasMsgUnread()) {
            return;
        }
        if (sessionData?.messages && hasMsgUnread() && popup === POPUP_NAMES.URGENTMESSAGES) {
            sessionStorage.removeItem("messageShownId");
            dispatch(setPopUp(null));
        }
        checkMessages();
    }, [sessionData]);

    const hasMsgUnread = () => {
        return sessionData?.messages?.find((message) => !message.isRead);
    };
    //Functions
    const checkMessages = () => {
        let showUrgentMessages = false;
        let showImportantMessages = false;
        sessionData.messages.map((msg) =>
            !msg.isRead && isCritical(msg.message) ? (showUrgentMessages = true) : null,
        );
        sessionData.messages.map((msg) =>
            !msg.isRead && isImportant(msg.message) ? (showImportantMessages = true) : null,
        );

        if (showUrgentMessages) {
            //if critical message && out of interface => show Zafiro interface
            STB.ensureZafiroInterface();
            launchMessagesPopup();
        }
        if (!showUrgentMessages && showImportantMessages) {
            if (Media.isVideoFullScreen || localStorage.getItem("channel_to_restore")) {
                dispatch(setMessagesNotShown(true));
            } else {
                launchMessagesPopup();
            }
        }
    };
    const launchMessagesPopup = () => {
        if (bannerOnScreen) {
            dispatch(setBannerOnScreen(false));
        }
        //Timeout to wait interface load
        setTimeout(() => {
            dispatch(setMessagesNotShown(false));
            dispatch(setPopUp(POPUP_NAMES.URGENTMESSAGES));
        }, 1000);
    };
    const checkAlarms = () => {
        if (
            statusRef?.current?.sessionData?.alarm &&
            statusRef.current.sessionData.alarm.state === "PENDING" &&
            (!popupRef.current || popupRef.current != POPUP_NAMES.ALARMACTIVE) &&
            !inPreview()
        ) {
            let msToAlarm =
                new Date(statusRef.current.sessionData.alarm.startTime).getTime() - new Date().getTime() - 5000; //5000 correspond to  alarm timeout play to ensure actions on power on end
            clearTimeout(alarmTimeoutRef.current);

            setTimeout(function () {
                //Timeout to wait interface load
                STB.powerOn();
                setTimeout(() => {
                    STB.ensureZafiroInterface();
                    dispatch(setPopUp(POPUP_NAMES.ALARMACTIVE));
                }, 5000);
            }, msToAlarm);
        }
    };
    const composeCurrenLayoutInfo = (cLayout) => {
        let info = "";
        if (!SESSION.isCommonZone()) {
            if (statusRef.current.sessionData && !statusRef.current.sessionData.stayguest) {
                return "Screen: No guest";
            } else if (!isWelcomed(statusRef.current.sessionData)) {
                return "Screen: Welcome";
            }
        }
        switch (cLayout) {
            case SCREEN:
                info = `Screen: ${screenUtils?.screenName || "unknown"}`;
                break;
            case HOME:
                info = `Home: ${uiRef.current.landingPage?.name}`;
                break;
            case CCINSTRUCTIONS_SCREEN:
                info = cLayout;
                break;
            case SIGNAGE:
                if (localStorage.getItem("commonZoneOptionKey") === ROOMTV_CONTENTS.TVCHANNELS.id) {
                    info = "Content: Tv";
                } else if (localStorage.getItem("commonZoneOptionKey") === ROOMTV_CONTENTS.CASTINSTRUCTIONSCREEN.id) {
                    info = "Content: Cast instructions";
                } else {
                    info = "Signage: " + SESSION.currentSignage.name;
                }
                break;
            default:
                info = `Section: ${cLayout || "unknown"}`;
                break;
        }

        return info;
    };

    const getMessagesFromStatus = (status) => {
        let _messages = [];
        let _messagesIDs = [];

        //Ensure msg id for messages from mqtt
        status.tvSession.messages.forEach((msg) => {
            msg.stayGuestMessageId && (msg.id = msg.stayGuestMessageId);
        });

        if (statusRef?.current?.sessionData?.messages) {
            _messages = statusRef?.current?.sessionData?.messages;
            _messages.forEach((_m) => {
                _messagesIDs.push(_m.id);
            });
        }
        status.tvSession.messages.reverse().forEach((newM) => {
            if (!_messagesIDs.includes(newM.id)) {
                _messages.unshift({
                    ...newM,
                    message: {
                        ...newM.message,
                        information: JSON.parse(newM.message.information)
                            ? { ...JSON.parse(newM.message.information), parsed: false }
                            : null,
                    },
                });
            }
        });
        return _messages;
    };
    //check signage each 5 minutes
    const mustIncludeSignageCheck = () => {
        if (!SESSION.hasPermission(PERMISSION.PRODUCT_SIGNAGE)) {
            return false;
        }
        if (new Date().getTime() - lastSignageCheckTime.current > MS_INTERVAL.ONE_MINUTE * 4) {
            lastSignageCheckTime.current = new Date().getTime();
            return true;
        }
        return false;
    };

    const sendStatus = (input = { preventCache: false, propagateError: true, includeSignage: false }) => {
        const currentLayout = getCurrentLayout();

        if (!sessionStorage.getItem("token") || currentLayout === "checkoutsuccess") {
            return;
        }
        STB.updatePowerState();

        Query({
            propagateError: input.propagateError,
            query: `
                        { 
                            status ( 
                                state: ${
                                    localStorage.getItem(STORAGE_KEY.POWER_STATE)
                                        ? localStorage.getItem(STORAGE_KEY.POWER_STATE)
                                        : STATE.ONLINE
                                }
                                ${input.preventCache ? "forceCheck: true" : ""}

                                ${
                                    input.includeSignage && SESSION.isCommonZone()
                                        ? ` currentSignageMd5:"${SESSION.currentSignage?.md5}"`
                                        : ""
                                }
                                ${
                                    input.includeSignage && SESSION.tickertapesEnabled()
                                        ? ` currentTickertapesMd5:"${SESSION.currentTickertapes?.md5}"`
                                        : ""
                                }
                                ${
                                    input.includeSignage && SESSION.bannersEnabled()
                                        ? ` currentBannersMd5:"${SESSION.currentBanners?.md5}"`
                                        : ""
                                }
                                ${
                                    sessionDataRef?.current?.hash
                                        ? ` hash:"${sessionDataRef.current.hash}"                                    
                                           currentLayout: "${composeCurrenLayoutInfo(currentLayout)}"`
                                        : ""
                                })
                          
                            {
                                appVersion
                                action
                                hash
                                tvSession{
                                    language
                                    room{
                                        id
                                        name
                                    }
                                    stayguest{
                                        id
                                        languageRef
                                        stayGuestRooms{
                                            id
                                            roomID
                                            welcomed
                                            isAdultEnabled
                                            parentalCode
                                        }
                                    }
                                    messages {
                                        id: stayGuestMessageId
                                        isRead
                                        message {                                            
                                            title
                                            body
                                            sentTime
                                            priority
                                            information
                                        }
                                    }
                                    alarm{
                                        id
                                        type
                                        startTime
                                        tvChannelId
                                        state
                                    }
                                }
                                signageMd5
                                tickertapesMd5
                                bannersMd5
                            }
                        }
                  `,

            onResponse(res) {
                SESSION.networkStatus = STATE.ONLINE;
                parseStatusResponse(res);
            },
            onError() {
                SESSION.networkStatus = STATE.OFFLINE;
            },
        });
    };

    const parseStatusResponse = (res) => {
        const deletingCredentials = localStorage.getItem("deletingCredentialsOnMultipleDevices");
        dispatch(showLoading(false));

        //If the TV is uninstalled - error 401 unauthorized
        if (res?.errors?.[0]?.message.includes("401")) {
            cleanLocalStorage();
            STB.reload();
        }

        if (res?.data && !res.data.status) {
            return;
        }

        const status = res.data.status;

        //check if current user is different to previous one
        if (status?.tvSession?.stayguest && localStorage.getItem("lastStayGuestId")) {
            if (localStorage.getItem("lastStayGuestId") !== String(status.tvSession.stayguest.id)) {
                STB.checkout();
                !STB.preventReloadOnCheckout && STB.reload();
            }
        }

        //REMOTE ACTIONS (Power-on, Power-off, reload)
        if (status.action) {
            executeAction({ status: res.data.status, dispatch });
        } else if (deletingCredentials) {
            //if reset credentials is not done in 3 minutes=> show error message
            const fiveMinutes = 1000 * 60 * 5;
            const now = new Date();
            const resetDate = new Date();
            resetDate.setTime(deletingCredentials);
            if (now.getTime() - resetDate.getTime() > fiveMinutes) {
                localStorage.removeItem("deletingCredentialsOnMultipleDevices");
                dispatch(displayErrorPopUp({ text: texts["reset-credentials-error"], timeout: 3000 }));
            }
        }

        if (status.hash && !sessionDataRef?.current?.hash && status.hash !== sessionDataRef?.current?.hash) {
            updateParentalCodeStore(status);
        }

        //TODO: get appVersion from status
        const appVersion = status.appVersion;
        if (appVersion) {
            if (localStorage.getItem("appVersion") && localStorage.getItem("appVersion") !== String(appVersion)) {
                localStorage.setItem("appVersion", appVersion);
                //There is a new tv app version and the TV is in standby mode => reload
                if (localStorage.getItem(STORAGE_KEY.POWER_STATE) === STATE.STANDBY) {
                    Logger.remoteLog("New Zafiro TV version and TV is in standby mode => reload");
                    STB.reload();
                } else {
                    dispatch(setTvAppOutdated());
                }
            } else if (!localStorage.getItem("appVersion")) {
                localStorage.setItem("appVersion", appVersion);
            }
        }

        if (!sessionDataRef.current) {
            dispatch(
                setSessionData({
                    hash: status.hash,
                    room: status.tvSession.room,
                    stayguest: status.tvSession.stayguest,
                    messages: getMessagesFromStatus(status),
                    alarm: status.tvSession.alarm,
                }),
            );

            if (!status.tvSession.stayguest || !status.tvSession.room) {
                cleanLocalStorage();
                localStorage.setItem("lang", "en");
                if (sessionData) {
                    Logger.remoteLog("TV changes to No Guest status");
                    STB.reload();
                }
                dispatch(setTexts(status.tvSession.language || "en"));
                //Local storage lang prevails over session guest lang
            } else if (!localStorage.getItem("lang")) {
                let lang = status.tvSession.language || "en";
                localStorage.setItem("lang", lang);
                dispatch(setTexts(lang));
            }
            executeAction({ status: res.data.status, dispatch });
        } else if (status.hash && sessionDataRef.current.hash && sessionDataRef.current.hash !== status.hash) {
            //If check-in
            if (!sessionDataRef.current.stayguest && status.tvSession.stayguest) {
                Logger.remoteLog("New guest checked in");
                STB.powerOn();
                STB.checkIn();
                STB.reload();
            } else if (sessionDataRef.current.stayguest && !status.tvSession.stayguest) {
                Logger.remoteLog("TV changes to No Guest status");
                cleanLocalStorage();
                STB.powerOff();
                STB.checkout();
                !STB.preventReloadOnCheckout && STB.reload();
            } else if (
                sessionDataRef.current.stayguest &&
                status.tvSession.stayguest &&
                status.tvSession.stayguest.id !== sessionDataRef.current.stayguest.id
            ) {
                Logger.remoteLog("Different guest id detected => TV reloads");
                cleanLocalStorage();
                STB.powerOn();
                STB.checkout();
                !STB.preventReloadOnCheckout && STB.reload();
            } else {
                updateParentalCodeStore(status);
                dispatch(
                    setSessionData({
                        hash: status.hash,
                        room: status.tvSession.room,
                        stayguest: status.tvSession.stayguest,
                        messages: getMessagesFromStatus(status),
                        alarm: status.tvSession.alarm,
                    }),
                );
            }
        }
    };

    const composePreviewGuest = () => {
        if (!inPreview()) {
            return null;
        }
        return {};
    };

    const executeAction = (param) => {
        switch (param?.status?.action?.toUpperCase()) {
            case "POWERON":
                STB.powerOn();
                break;
            case "POWEROFF":
                STB.powerOff();
                break;
            case "RELOAD":
                if (sessionStorage.getItem("outOfInterface") === "true") {
                    localStorage.setItem("remoteReload", true);
                }
                STB.ensureZafiroInterface();
                dispatch(setPopUp("reload"));
                break;
            case "RESETCREDENTIALS":
                !localStorage.getItem("deletingCredentialsOnMultipleDevices") && STB.resetCredentials();
                break;
            case "RESETCREDENTIALSCOMPLETED":
                if (localStorage.getItem("deletingCredentialsOnMultipleDevices")) {
                    dispatch(setPopUp("resetCredentials-success"));
                    localStorage.removeItem("deletingCredentialsOnMultipleDevices");
                }
                break;
            case "RENEWSIGNAGE":
                param?.status?.signageMd5 &&
                    SESSION.signageEnabled() &&
                    SESSION.currentSignage?.md5 != param?.status?.signageMd5 &&
                    dispatch(setRenewSignage(true));
                param?.status?.tickertapesMd5 &&
                    SESSION.tickertapesEnabled() &&
                    SESSION.currentTickertapes?.md5 != param?.status?.tickertapesMd5 &&
                    dispatch(setRenewTickertapes(true));
                param?.status?.bannersMd5 &&
                    SESSION.bannersEnabled() &&
                    SESSION.currentBanners?.md5 != param?.status?.bannersMd5 &&
                    dispatch(setRenewBanners(true));

                break;

            default:
                break;
        }
    };
};
export const cleanLocalStorage = () => {
    localStorage.removeItem("lang");
    localStorage.removeItem("channel_to_restore");
    localStorage.removeItem("cart");
    localStorage.removeItem("haGridEnsured");
    localStorage.removeItem("haGridEnsuredDate");
    sessionStorage.removeItem("parentalCode");
    resetAllQueryCache();
};

const updateParentalCodeStore = (_status) => {
    const parentalCode = _status?.tvSession?.stayguest?.stayGuestRooms?.find(
        (room) => parseInt(room.roomID) == parseInt(_status?.tvSession?.room.id),
    )?.parentalCode;
    if (parentalCode) {
        sessionStorage.setItem("parentalCode", parentalCode);
    } else {
        sessionStorage.removeItem("parentalCode");
    }
};

export default Notifications;
