import { useEffect, useRef, useState } from "preact/hooks";
import { checkPriorityOfItems, getTextOfTickerTape, TICKER_TAPE } from "../../utils/tickerTapeAndBannerUtils";
import { figmapx2vh, figmapx2vw } from "../../utils/utils";
import ReactHtmlParser from "react-html-parser";
import { isInHourRange, parseHourMinuteSeconds } from "../../utils/signageUtils";
import { SESSION } from "../../utils/session";
import { MS_INTERVAL } from "../../utils/constants";
import { SHA1 } from "../../utils/hash";
import { Media } from "../../hooks/apis/media";

const SPACE_BETWEEN_TAPES = 200;

const TickerTape = ({ tickerTapes }) => {
    const parseTickerTape = (_tickerTape) => {
        const tickerTapeSize = _tickerTape?.displayTextSize
            ? TICKER_TAPE.SIZE[_tickerTape.displayTextSize]
            : TICKER_TAPE.SIZE.MEDIUM;

        const tickerTapeColors = {
            color: _tickerTape?.displayStyles?.fgColor || "#0F63BD",
            backgroundColor: _tickerTape?.displayStyles?.bgColor || "rgba(255,255,255,0.6)",
        };
        const tickerTapeSpeed = _tickerTape?.displayScrollSpeed
            ? TICKER_TAPE.SPEED[_tickerTape.displayScrollSpeed]
            : TICKER_TAPE.SPEED.MEDIUM;
        const tickerTapeText = getTextOfTickerTape(_tickerTape);
        const tickerTapeTimeRanges = _tickerTape.timeRanges.find((range) => isInHourRange(range));

        return {
            ..._tickerTape,
            tickerTapeSize,
            tickerTapeColors,
            tickerTapeSpeed,
            tickerTapeText,
            tickerTapeTimeRanges,
            tickerTapeTimeRanges,
        };
    };

    const divText = useRef();
    const intervalScroll = useRef();
    const displayTimeout = useRef();
    const checkTimeout = useRef(null);

    if (!tickerTapes || tickerTapes.length === 0) {
        if (checkTimeout.current) {
            clearTimeout(checkTimeout.current);
        }
        return null;
    }

    const [tickerTape, setTickerTape] = useState(parseTickerTape(tickerTapes[0]));

    useEffect(() => {
        checkTimingOfTickerTapes();
        return () => {
            SESSION.currentTickertapes.id = null;
            SESSION.currentTickertapes.sha1 = null;
            clearTimeout(checkTimeout.current);
            clearInterval(intervalScroll.current);
        };
    }, []);

    useEffect(() => {
        checkTimingOfTickerTapes();
    }, [tickerTapes]);

    useEffect(() => {
        if (
            SHA1(JSON.stringify(tickerTape)) !== SESSION.currentTickertapes.sha1 &&
            (tickerTape.scheduleObtrusiveMode || !Media.isVideoFullScreen)
        ) {
            SESSION.currentTickertapes.id = tickerTape.id;
            SESSION.currentTickertapes.sha1 = SHA1(JSON.stringify(tickerTape));
            startTickerTape();
        }
    }, [tickerTape]);

    useEffect(() => {
        if (!tickerTape.scheduleObtrusiveMode && Media.isVideoFullScreen) {
            stopMarquee();
        } else if (!tickerTape.scheduleObtrusiveMode && !Media.isVideoFullScreen) {
            startTickerTape();
        }
    }, [Media.isVideoFullScreen]);

    const checkTimingOfTickerTapes = () => {
        if (checkTimeout.current) {
            clearTimeout(checkTimeout.current);
        }
        if (tickerTapes.length > 1) {
            const newTickerTape = checkPriorityOfItems(tickerTapes);
            const newTTSha1 = SHA1(JSON.stringify(newTickerTape[0]));
            if (newTickerTape && newTickerTape.length > 0 && newTTSha1 !== SESSION.currentTickertapes.sha1) {
                setTickerTape(parseTickerTape(newTickerTape[0]));
            }
        }
        checkTimeout.current = setTimeout(
            function () {
                checkTimingOfTickerTapes();
            },

            MS_INTERVAL.ONE_MINUTE - new Date().getSeconds() * 1000,
        );
    };

    const startTickerTape = () => {
        divText.current.innerHTML = "";
        if (tickerTape.scheduleFrequencyLimited) {
            divText.current.innerHTML = addTimesText(tickerTape.scheduleFrequencyRepetitions);
        } else {
            //  loop during timeRange active
            divText.current.innerHTML = addTimesText(3, { norightpadding: true });
        }

        marqueeDiv(divText.current);
    };

    const stopMarquee = () => {
        document.getElementById("tickertape-background").style.visibility = "hidden";
        clearInterval(intervalScroll.current);
        intervalScroll.current = null;
        divText.current.style.marginLeft = document.body.offsetWidth + "px";
    };

    const addTimesText = (times, options) => {
        const htmlElemnts = [];
        for (let index = 0; index < times; index++) {
            htmlElemnts.push(
                `<span style="padding-left: ${figmapx2vw(SPACE_BETWEEN_TAPES)};padding-right: ${figmapx2vw(
                    index === times - 1 && !options?.norightpadding ? SPACE_BETWEEN_TAPES : 0,
                )}">${tickerTape.tickerTapeText}</span>`,
            );
        }
        return htmlElemnts.join("");
    };

    const addNextRepetition = () => {
        const newRepetition = document.createElement("span");
        newRepetition.style.paddingLeft = figmapx2vw(SPACE_BETWEEN_TAPES);
        newRepetition.innerHTML = tickerTape.tickerTapeText;
        divText.current.appendChild(newRepetition);
    };

    const marqueeDiv = (divToMarquee) => {
        if (displayTimeout.current) {
            clearTimeout(displayTimeout.current);
        }
        if (tickerTape.scheduleFrequencyLimited) {
            const _frq = parseHourMinuteSeconds(tickerTape.scheduleFrequencyDisplay);
            displayTimeout.current = setTimeout(
                function () {
                    marqueeDiv(divToMarquee);
                }.bind(divToMarquee),
                (_frq.hour * 3600 + _frq.min * 60 + _frq.sec) * 1000,
            );
        }
        document.getElementById("tickertape-background").style.visibility = "visible";
        divToMarquee.style.marginLeft = document.body.offsetWidth + "px";
        clearInterval(intervalScroll.current);
        intervalScroll.current = setInterval(
            function () {
                const margin = divToMarquee.style.marginLeft.replace("px", "");
                divToMarquee.style.marginLeft = margin - document.body.offsetWidth / tickerTape.tickerTapeSpeed + "px";
                if (parseInt(divToMarquee.style.marginLeft.replace("px", "")) * -1 > divToMarquee.offsetWidth) {
                    document.getElementById("tickertape-background").style.visibility = "hidden";
                    clearInterval(intervalScroll.current);
                    divToMarquee.style.marginLeft = document.body.offsetWidth + "px";
                }
                if (
                    !tickerTape.scheduleFrequencyLimited &&
                    parseInt(divToMarquee.style.marginLeft.replace("px", "")) * -1 > divToMarquee.offsetWidth / 8
                ) {
                    addNextRepetition();
                }
            }.bind(divToMarquee),
            25,
        );
    };
    if (!tickerTape) {
        return null;
    }

    return (
        <div
            id={"tickertape-background"}
            className={"tickertape"}
            style={{
                bottom: figmapx2vh(72),
                fontSize: figmapx2vh(tickerTape.tickerTapeSize.fontSize),
                height: figmapx2vh(tickerTape.tickerTapeSize.bgHeight),
                color: tickerTape.tickerTapeColors.color,
                backgroundColor: tickerTape.tickerTapeColors.backgroundColor,
                visibility: "hidden",
            }}
        >
            <div
                id={"tickertape-text"}
                ref={divText}
                // className={"animateMarginLeftTickerTape"}
                // className={"animateMarginLeft"}
                style={{
                    marginLeft: document.body.offsetWidth,
                    width: "auto",
                    whiteSpace: "nowrap",
                    scrollBehavior: "smooth",
                }}
                // style={{ marginLeft: 500, width: "auto" }}
            ></div>
        </div>
    );
};

export default TickerTape;
