import { useDispatch, useSelector } from "react-redux";
import Button from "../../components/common/button";
import { Texts } from "../../utils/texts";
import { BANNER, checkPriorityOfItems, getBannerMargins } from "../../utils/tickerTapeAndBannerUtils";
import {
    completeDasURL,
    composeNavigationPath,
    figmaHeightPx2Px,
    figmapx2vh,
    figmapx2vw,
    figmaWidthPx2Px,
} from "../../utils/utils";
import { useEffect, useRef, useState } from "preact/hooks";
import focus from "../../utils/focus";
import { eventHandler } from "../../utils/eventHandler";
import { KEYS } from "../../utils/keys";
import { Theme } from "../../utils/theme";
import { navigateTo, setBannerOnScreen, setPopUp } from "../../actions/uiActions";
import { CONTENT, POPUP_NAMES, SCREEN, SECTION, WIDGETS } from "../../utils/constants";
import { parseAction } from "../../utils/screenUtils";
import { Media } from "../../hooks/apis/media";

const Banner = ({ banners }) => {
    const dispatch = useDispatch();
    const { texts, popup, bannerOnScreen } = useSelector((state) => state.ui);

    const [banner, setBanner] = useState(banners[0]);
    const [bannerImageSize, setBannerImageSize] = useState(null);
    const [displayBanner, setDisplayBanner] = useState(false);

    if (!banners || banners.length === 0) {
        return null;
    }

    useEffect(() => {
        const newPriorityBanners = checkPriorityOfItems(banners);
        setBanner(newPriorityBanners?.[0]);
    }, [banners]);

    useEffect(() => {
        if (displayBanner) {
            document.querySelector("#banner_item").style.animation = "bannerFadeIn 1.8s";
        }
    }, [displayBanner]);

    const { y_align, x_align } = getBannerMargins(banner, bannerImageSize);
    const shorterThanBannerSize = useRef(false);
    useEffect(() => {
        let imageObj = new Image();

        imageObj.onload = function () {
            let _imgSize = {
                width: imageObj.offsetWidth,
                height: imageObj.offsetHeight,
                buttonsHeight: BANNER.SIZE.BUTTONS.height,
            };
            if (
                imageObj.offsetWidth < figmaWidthPx2Px(BANNER.SIZE[banner.displaySize].width) &&
                imageObj.offsetWidth < figmaHeightPx2Px(224 * 2 + 64 + 12) &&
                banner.content.navigation
            ) {
                shorterThanBannerSize.current = true;
                _imgSize.buttonsHeight = 184;
            }
            setBannerImageSize(_imgSize);
        };
        imageObj.onerror = function () {};
        imageObj.src = banner.content.asset;
        imageObj.classList.add("block");
        imageObj.classList.add("mx-auto");
        const bannerImage = document.getElementById("banner_image");
        if (bannerImage) {
            bannerImage.appendChild(imageObj);
        }
    }, [banner.content.asset]);

    useEffect(() => {
        // TEST: with 2s delay so show banner
        setTimeout(() => {
            if (popup !== POPUP_NAMES.URGENTMESSAGES || popup !== POPUP_NAMES.ALARMACTIVE) {
                dispatch(setPopUp(null));
            }
            if (!popup) {
                showBanner();
            }
        }, 1500);
    }, []);

    useEffect(() => {
        if (!bannerOnScreen) {
            closeBanner();
        }
    }, [bannerOnScreen]);

    useEffect(() => {
        if (!banner.scheduleObtrusiveMode && Media.isVideoFullScreen) {
            closeBanner();
        }
    }, [Media.isVideoFullScreen]);

    const showBanner = () => {
        if (!banner.scheduleObtrusiveMode && !Media.isVideoFullScreen) {
            dispatch(setBannerOnScreen(true));
            setDisplayBanner(true);
            focus.value.stack("btn-close");
        }
    };

    const closeBanner = () => {
        dispatch(setBannerOnScreen(false));
        setDisplayBanner(false);
        focus.value.unstack();
    };

    const onBannerKeydown = (e) => {
        const keyData = eventHandler.getKeyData(e);
        let preventDefault = true;
        const allowKeys = [KEYS.up, KEYS.down, KEYS.left, KEYS.right, KEYS.enter];
        if (allowKeys.includes(keyData?.value)) {
            preventDefault = false;
        }
        // TODO: 3 veces home recarga interface ???
        if (preventDefault) {
            eventHandler.stopPropagation(e);
        }
    };
    return (
        <div
            id="banner_layout"
            className={`w-full h-full inline-flex absolute top-0`}
            style={{
                padding: figmapx2vw(72),
                backgroundColor: "rgba(87,101,117,0.7)",
                visibility: `${displayBanner ? "visible" : "hidden"}`,
                zIndex: 799,
            }}
            onKeyDown={(e) => {
                onBannerKeydown(e);
            }}
        >
            <div
                id="banner_item"
                className={`${y_align}`}
                style={{
                    width: bannerImageSize?.width + "px" || figmapx2vw(BANNER.SIZE[banner.displaySize].width),
                    height:
                        bannerImageSize?.height + figmaHeightPx2Px(bannerImageSize?.buttonsHeight) + "px" ||
                        figmapx2vh(BANNER.SIZE[banner.displaySize].height + BANNER.SIZE.BUTTONS.height),
                    marginLeft: x_align,
                    minWidth: figmapx2vw(288),
                }}
            >
                <div
                    id="banner_image"
                    className={"bg-gray-900"}
                    style={{
                        height: bannerImageSize?.height + "px" || figmapx2vh(BANNER.SIZE[banner.displaySize].height),
                    }}
                ></div>
                <div
                    id="banner_buttons"
                    className={`${
                        Theme.light ? "bg-white text-gray-900" : "bg-gray-800 text-gray-100"
                    } align-content-center w-full`}
                    style={{
                        paddingLeft: figmapx2vh(32),
                        paddingRight: figmapx2vh(32),
                        height: figmapx2vh(bannerImageSize?.buttonsHeight || BANNER.SIZE.BUTTONS.height),
                    }}
                >
                    <div className={`${shorterThanBannerSize.current ? "inline-block" : "inline-flex"} w-full h-full`}>
                        <div
                            className={`${
                                !banner?.content?.navigation
                                    ? "w-full"
                                    : shorterThanBannerSize.current
                                    ? "block"
                                    : " w-1/2"
                            } my-auto`}
                            style={{
                                marginTop: figmapx2vh(shorterThanBannerSize.current ? 24 : "auto"),
                                paddingRight: figmapx2vw(shorterThanBannerSize.current ? 0 : 12),
                            }}
                        >
                            <Button
                                insideModal={true}
                                data={{
                                    id: "close",
                                    name: Texts.capitalize(texts["close"]),
                                    border: true,
                                    customClass: ` py-2 px-4 text-lg text-center rounded mx-auto `,
                                    customStyle: {
                                        width: "100%",
                                        maxWidth: figmapx2vw(600),
                                        minWidth: figmapx2vw(224),
                                    },
                                    onClick() {
                                        closeBanner();
                                    },
                                }}
                            />
                        </div>
                        {banner?.content?.navigation && (
                            <div
                                className={`${shorterThanBannerSize.current ? "block" : "w-1/2"} my-auto`}
                                style={{
                                    marginTop: figmapx2vh(shorterThanBannerSize.current ? 24 : "auto"),
                                    paddingLeft: figmapx2vw(shorterThanBannerSize.current ? 0 : 12),
                                }}
                            >
                                <Button
                                    insideModal={true}
                                    data={{
                                        id: "showMore",
                                        name: Texts.translateCap(texts, "show more"),
                                        border: true,
                                        customClass: ` py-2 px-4 text-lg text-center rounded mx-auto ${
                                            Theme.light ? "bg-white text-gray-900" : "bg-gray-800 text-gray-100"
                                        }`,
                                        customStyle: {
                                            width: "100%",
                                            maxWidth: figmapx2vw(600),
                                            minWidth: figmapx2vw(224),
                                        },
                                        onClick() {
                                            //close and navigate
                                            closeBanner();

                                            eventHandler.dispatchNavigation({
                                                dispatch,
                                                action: parseAction(banner?.content?.navigation),
                                            });
                                        },
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Banner;
