import Signage from "../layouts/commonZones/Signage";
import { DEFAULT } from "./constants";
import { Banner, TickerTape } from "./tickerTapeAndBannerUtils";

export enum SignageContentType {
    SCREEN = "SCREEN",
    ASSET = "ASSET",
    VIDEO = "VIDEO",
}
export enum ScheduleDailyType {
    ALL_DAY = "ALL_DAY",
    TIME_RANGE = "TIME_RANGE",
}
export enum ScheduleDaysType {
    ALL_DAYS = "ALL_DAYS",
    SPECIFIC_DAYS = "SPECIFIC_DAYS",
}

export type TimeRanges = {
    endTime: string;
    startTime: string;
};

export type Content = {
    duration: number;
    id: number;
    pos: number;
    ref: string;
    signageID: number;
    type: SignageContentType;
};

export type Landing = {
    designID: number;
    contentStyle: string;
};
export type Signage = {
    available: boolean;
    contentDefaultFilesDuration: number;
    contents: Content[];
    contentLanguageRef: string;
    id: number;
    isDefault: boolean;
    name: string;
    scheduleEnd: string;
    scheduleStart: string;
    scheduleDaysType: ScheduleDaysType | null;
    scheduleDaysOfWeek: number[];
    scheduleDailyType: ScheduleDailyType | null;
    timeRanges: TimeRanges[] | null;
};

export const getDefaultSequence = (sequences: Signage[]): Signage | undefined | null => {
    if (!sequences || sequences.length === 0) {
        return null;
    }
    return sequences.find((sequence) => sequence.isDefault);
};

export const isInHourRange = (range: TimeRanges): boolean => {
    const _now = new Date();
    const aH = parseInt(range.startTime.split(":")[0] || "0");
    const aM = parseInt(range.startTime.split(":")[1] || "0");
    const bH = parseInt(range.endTime.split(":")[0] || "0");
    const bM = parseInt(range.endTime.split(":")[1] || "0");
    if (
        (aH < _now.getHours() && bH > _now.getHours()) ||
        (aH === _now.getHours() && bH > _now.getHours() && aM <= _now.getMinutes()) ||
        (aH <= _now.getHours() && aM <= _now.getMinutes() && bH === _now.getHours() && bM > _now.getMinutes())
    ) {
        return true;
    }
    return false;
};

export const isActiveSequence = (
    sequence: Signage | TickerTape | Banner,
): { active: boolean; level: number; intervalTime: number } => {
    const _now = new Date();
    const noActive = { active: false, level: 0, intervalTime: 0 };
    let startOfSchedule = new Date(sequence.scheduleStart);
    startOfSchedule.setHours(0, 0, 0, 0);
    let endOfSchedule = new Date(sequence.scheduleEnd);
    endOfSchedule.setHours(23, 59, 59, 999);

    if (startOfSchedule > _now || endOfSchedule < _now) {
        return noActive;
    }
    if (
        sequence.scheduleDaysType === ScheduleDaysType.ALL_DAYS &&
        (!sequence?.timeRanges || sequence.timeRanges.length === 0) &&
        sequence?.scheduleDaysOfWeek.length === 0
    ) {
        return { active: true, level: 1, intervalTime: calcDiffIntervalTime(startOfSchedule, endOfSchedule) };
    }
    if (
        sequence.scheduleDaysOfWeek.includes(_now.getDay()) &&
        sequence.scheduleDailyType === ScheduleDailyType.ALL_DAY
    ) {
        return { active: true, level: 2, intervalTime: calcDiffIntervalTime(startOfSchedule, endOfSchedule) };
    }
    if (
        sequence.scheduleDaysType === ScheduleDaysType.ALL_DAYS &&
        sequence?.timeRanges?.find((range) => isInHourRange(range))
    ) {
        const rangeActive = sequence?.timeRanges?.find((range) => isInHourRange(range));
        return {
            active: true,
            level: 3,
            intervalTime: calcDiffIntervalTime(startOfSchedule, endOfSchedule, rangeActive),
        };
    }

    if (
        sequence.scheduleDaysOfWeek.includes(_now.getDay()) &&
        sequence?.timeRanges &&
        sequence?.timeRanges.find((range) => isInHourRange(range))
    ) {
        const rangeActive = sequence?.timeRanges?.find((range) => isInHourRange(range));
        return {
            active: true,
            level: 4,
            intervalTime: calcDiffIntervalTime(startOfSchedule, endOfSchedule, rangeActive),
        };
    }
    return noActive;
};

const calcDiffIntervalTime = (startDay: Date, endDay: Date, _timeRange?: TimeRanges): number => {
    let _firstDate = startDay;
    let _lastDate = endDay;
    if (_timeRange) {
        const startRange = parseHourMinute(_timeRange?.startTime || "");
        const endRange = parseHourMinute(_timeRange?.endTime || "");
        _firstDate = new Date();
        _lastDate = new Date();
        _firstDate.setHours(startRange.hour, startRange.min);
        _lastDate.setHours(endRange.hour, endRange.min);
    }
    return _lastDate.getTime() - _firstDate.getTime();
};
export const fullContentDuration = (contents: Content[]): number => {
    if (!contents || contents.length === 0) {
        return 0;
    }
    return contents.reduce((accumulator, content) => accumulator + content.duration, 0);
};

export const parseHourMinute = (time: string): { hour: number; min: number } => {
    if (time === DEFAULT || time.indexOf(":") < 0) {
        return { hour: 0, min: 0 };
    }
    const hour = parseInt(time.split(":")[0] || "0");
    const min = parseInt(time.split(":")[1] || "0");
    return { hour, min };
};
export const parseHourMinuteSeconds = (time: string): { hour: number; min: number; sec: number } => {
    if (time === DEFAULT || time.indexOf(":") < 0) {
        return { hour: 0, min: 0, sec: 0 };
    }
    const hour = parseInt(time.split(":")[0] || "0");
    const min = parseInt(time.split(":")[1] || "0");
    const sec = parseInt(time.split(":")[2] || "0");
    return { hour, min, sec };
};

export const calculateElapseTimeForCurrentLoop = (sequence: Signage): number => {
    // default and all day sequences start al 00:00
    let timeScheduleStarted: string = DEFAULT;
    if (!sequence.isDefault && sequence.scheduleDailyType === ScheduleDailyType.TIME_RANGE) {
        timeScheduleStarted = sequence.timeRanges?.find((range) => isInHourRange(range))?.startTime || DEFAULT;
    }
    const startTime = parseHourMinute(timeScheduleStarted);
    const _now = new Date();
    const diffHour = _now.getHours() - startTime.hour;
    const diffMin = _now.getMinutes() - startTime.min;
    const sequenceDuration = fullContentDuration(sequence.contents);
    const totalSecondsFromStart = (diffHour * 60 + diffMin) * 60 + _now.getSeconds();
    return totalSecondsFromStart % sequenceDuration;
};

type ActiveSignage = {
    id: number;
    active: boolean;
    activeLevel: number;
    intervalTime: number;
};

export const orderSignageByActiveLevelAndId = (allSequences: ActiveSignage[]): ActiveSignage[] => {
    return allSequences
        ?.filter((item) => item.active)
        .sort((a, b) => {
            const res = b.activeLevel - a.activeLevel;
            if (res) {
                return res;
            }
            const intervalDiff = a.intervalTime - b.intervalTime;
            if (intervalDiff) {
                return intervalDiff;
            }
            return b.id - a.id;
        });
};
