import Button from "../../components/common/button";
import { MOVIES } from "../../utils/constants";
import { eventHandler } from "../../utils/eventHandler";
import focus from "../../utils/focus";
import { KEYS } from "../../utils/keys";
import { moveFocus } from "../../utils/movement";
import { figmapx2vh, figmapx2vw } from "../../utils/utils";

const SPECIAL_BUTTON_CATEGORY_ID = "btn-specialCategory";
const FILTER_BUTTON_ID = "btn-filter";

const Categories = ({
    gridData,
    activeCategory,
    setActiveCategory,
    fullGroup = false,
    specialButton = false,
    filterButton = false,
}) => {
    const focusNextCategory = () => {
        const _name = document.activeElement.id.replace("btn-", "");
        const catIndex = gridData.findIndex((cat) => cat.id === _name);
        if (catIndex + 1 >= gridData.length) {
            return;
        }
        focus.value.replace(`btn-${gridData[catIndex + 1].id}`);
    };

    const inLastCategoryButton = () => {
        const last_cat = gridData[gridData.length - 1].id;
        const specialButton_exist = document.getElementById(SPECIAL_BUTTON_CATEGORY_ID);
        if (
            (specialButton_exist && focus.value.current === SPECIAL_BUTTON_CATEGORY_ID) ||
            (focus.value.current === `btn-${last_cat}` && !specialButton_exist)
        ) {
            return true;
        }
        return false;
    };

    const focusPreviousCategory = () => {
        const _name = document.activeElement.id.replace("btn-", "");
        const catIndex = gridData.findIndex((cat) => cat.id === _name);
        if (catIndex === 0) {
            if (filterButton && activeCategory === MOVIES.FILTERS.ALL_MOVIES) {
                focus.value.replace(FILTER_BUTTON_ID);
            }
            return;
        } else if (catIndex < 0 || focus.value.current === SPECIAL_BUTTON_CATEGORY_ID) {
            focus.value.replace(`btn-${gridData[gridData.length - 1].id}`);
            return;
        }
        focus.value.replace(`btn-${gridData[catIndex - 1].id}`);
    };

    const onCategoriesKeyDown = (e) => {
        const keyData = eventHandler.getKeyData(e);
        let preventDefault = false;
        if (keyData) {
            switch (keyData.value) {
                case KEYS.up:
                    preventDefault = true;
                    break;
                case KEYS.right:
                    if (!specialButton) {
                        focusNextCategory();
                        preventDefault = true;
                    } else if (inLastCategoryButton()) {
                        preventDefault = true;
                    }
                    break;
                case KEYS.left:
                    if (
                        focus.value.current !== SPECIAL_BUTTON_CATEGORY_ID ||
                        focus.value.current !== FILTER_BUTTON_ID
                    ) {
                        focusPreviousCategory();
                        preventDefault = true;
                    }
                    break;
                case KEYS.down:
                    const nextMovieDown =
                        moveFocus(document.activeElement.id, "down") ||
                        `btn-${gridData.find((category) => category.id === activeCategory).items[0].id}_movie_${
                            gridData.find((category) => category.id === activeCategory).items[0].items[0].id
                        }`;
                    // focus in first movie of first group of catergory
                    focus.value.replace(nextMovieDown);
                    preventDefault = true;
                    break;
                default:
                    break;
            }
        }

        if (preventDefault) {
            eventHandler.stopPropagation(e);
        }
    };

    return (
        <div
            className="filters"
            style={{
                height: figmapx2vh(200),
                fontSize: figmapx2vw(40),
                display: fullGroup ? "none" : "",
                width: "100vw",
                zIndex: 10,
                background: `linear-gradient(0deg, rgba(55, 61, 66, 0) 30.4%, rgba(55, 61, 66, 0.62) 61.02%, #373D42 100%)`,
            }}
            onKeyDown={(e) => {
                onCategoriesKeyDown(e);
            }}
        >
            {filterButton && activeCategory === MOVIES.FILTERS.ALL_MOVIES && (
                <div
                    style={{
                        position: "absolute",
                        left: figmapx2vh(72),
                        top: "7.2vh",
                        width: figmapx2vw(327),
                        height: figmapx2vh(70),
                    }}
                >
                    {filterButton()}
                </div>
            )}
            {specialButton && (
                <div
                    style={{
                        position: "absolute",
                        right: figmapx2vh(80),
                        top: "6.8vh",
                        width: figmapx2vh(243),
                        height: figmapx2vh(70),
                    }}
                >
                    {specialButton()}
                </div>
            )}
            <div className="mx-auto table text-center">
                {gridData.map((gFilter) => {
                    if (!gFilter || gFilter.hide) {
                        return;
                    }

                    return (
                        <div key={gFilter.id} className="float-left">
                            <Button
                                data={{
                                    id: gFilter.id,
                                    customClass: `filter ${gFilter.id === activeCategory ? "font-700" : ""}`,
                                    focusClass: "channelList-focus",
                                    removeButtonClass: true,
                                    customAttribute: "category",
                                    name: gFilter.name,
                                    onClick() {
                                        if (gFilter.id !== activeCategory) {
                                            setActiveCategory(gFilter.id);
                                        }
                                    },
                                }}
                            />
                            {activeCategory === gFilter.id && focus.value.current !== `btn-${gFilter.id}` ? (
                                <div className={"border mx-auto"} style={{ width: "31%" }}></div>
                            ) : null}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default Categories;
