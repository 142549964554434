import { Provider } from "react-redux";
import store from "./store";
import InitSystem from "./initSys";
import { isElementVisible } from "./utils/movement";

//WidgetsRender uses isElementVisible
document.ELEM_UTILS = { isVisible: (elem) => isElementVisible(elem) };

const App = () => {
    return (
        <Provider store={store}>
            <InitSystem />
        </Provider>
    );
};

export default App;
