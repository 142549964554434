const Icon = ({ widget }) => {
    const widgetData = JSON.parse(widget.data);
    const widgetStyle = JSON.parse(widget.widgetStyle);

    if (!widgetData || !widgetData.icon) {
        return;
    }
    return (
        <div className="table w-full h-full">
            <div
                className={` table-cell vertical-middle text-center ${widgetData.lib} ${widgetData.lib}-${widgetData.icon}`}
                style={{
                    color: `${widgetStyle ? widgetStyle.fgColor : ""}`,
                    fontSize: `${widgetData.size || 5}vw`,
                    borderRadius: `${widgetStyle && widgetStyle.radius ? `${widgetStyle.radius}rem` : ""}`,
                }}
            />
        </div>
    );
};
export default Icon;
