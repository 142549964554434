import { useSelector } from "react-redux";

//Components
import Menu from "./components/menu";
import Installation from "./components/installation";
import Content from "./components/content";
import Modal from "./components/common/modal";
import Popups from "./components/popups";
import { isWelcomed, printCenteredMessage, sessionStoreAllShops } from "./utils/utils";
import Notifications from "./hooks/GraphqlCalls/notifications";
import Background from "./components/background";
import { CSSTransition } from "react-transition-group";
import { Theme } from "./utils/theme";
import focus from "./utils/focus";
import Welcome from "./components/welcome";
import ReactHtmlParser from "react-html-parser";
import GetLanding from "./hooks/GraphqlCalls/getLanding";
import { ANIMATION_TIMEOUT, MENU_TYPES } from "./utils/constants";
import { useEffect, useRef } from "preact/hooks";
import { STB } from "./hooks/apis/stb";
import Limbo from "./components/screen/limbo";

import PopupError from "./components/common/popupError";
import ConnectionError from "./components/connectionError";
import Tv from "./layouts/tv/tv";
import { SESSION } from "./utils/session";
import { Texts } from "./utils/texts";
import api from "./components/sales/api";
import CommonZoneOnTv from "./layouts/commonZones/CommonZoneOnTv";
import TickerTapeAndBanners from "./layouts/tickertapeAndBanners/TickerTapeAndBanners";

const AppContent = () => {
    let loadingTimeout = useRef();
    let MAX_LOADING_TIME = 60000;

    //Store data
    const installed = useSelector((state) => state.status.installed);
    const serverOnline = useSelector((state) => state.status.serverOnline);
    const sessionData = useSelector((state) => state.status.sessionData);
    const fontsReady = useSelector((state) => state.status.fontsReady);
    const librariesReady = useSelector((state) => state.status.librariesReady);
    const connectionError = useSelector((state) => state.status.connectionError);
    const centerFullScreenMsg = useSelector((state) => state.status.centerFullScreenMsg);
    const locationData = useSelector((state) => state.status.locationData);

    const loading = useSelector((state) => state.ui.loading);
    const loadingResources = loading || !fontsReady || !librariesReady;
    const texts = useSelector((state) => state.ui.texts);
    const { showMenu, menu, landingPage } = useSelector((state) => state.ui);
    const isHiddenMenu = menu?.temporaryType === MENU_TYPES.HIDDEN || menu?.type === MENU_TYPES.HIDDEN;
    if (installed && sessionData && !SESSION.isCommonZone()) {
        //cache to sessionStorage current shops
        sessionStoreAllShops();
        GetLanding();
    }

    useEffect(() => {
        installed && sessionData && !sessionData.stayguest && focus.value.replace("noFocus");
    }, [installed, sessionData]);

    //recover interface if Zafiro load fails
    useEffect(() => {
        clearTimeout(loadingTimeout.current);
        if (loading || !serverOnline) {
            loadingTimeout.current = setTimeout(() => {
                STB.reload();
            }, MAX_LOADING_TIME);
        }
        if (loading) {
            setTimeout(function () {
                if (!window.Render) {
                    STB.reload();
                }
            }, MAX_LOADING_TIME);
        }
    }, [loading, serverOnline]);

    const waitingForResources = () => {
        console.log("waiting to load all resources (check WidgetsRender or other library)!");
        return (
            <div style={{ fontFamily: "Lato", fontWeight: "bold" }}>
                {printCenteredMessage(texts["loading-zafiro"] + " ...", null, "black", "white")}
            </div>
        );
    };

    return (
        <div style={Theme.getBodyStyle()}>
            <div
                id="log"
                className="fixed top-0 left-0 z-100 p-4"
                style={{
                    color: "red",
                    zIndex: 1000,
                }}
            />
            {installed ? (
                <>
                    <Notifications />
                    <Background />
                </>
            ) : null}

            {connectionError ? (
                <div style={{ fontFamily: "Lato", fontWeight: "bold" }}>
                    {printCenteredMessage(ReactHtmlParser(texts["connection-error"]), null, "black", "white")}
                </div>
            ) : centerFullScreenMsg ? (
                <div style={{ fontFamily: "Lato", fontWeight: "bold" }}>
                    {printCenteredMessage(
                        ReactHtmlParser(Texts.translate(texts, "NO STB.ref")),
                        null,
                        "black",
                        "white",
                    )}
                </div>
            ) : loadingResources ? (
                waitingForResources()
            ) : SESSION.isCommonZone() ? (
                <CommonZoneOnTv />
            ) : installed && sessionData ? (
                SESSION.isTVOnCheckout() ? (
                    <Tv />
                ) : !sessionData.stayguest ? (
                    <Modal
                        title={`${locationData?.projectName}`}
                        subtitle={locationData.roomInfo?.name}
                        body={<div className={"text-lg text-center"}> {texts["no-guest"]}</div>}
                        style={{ container: "h-10rem" }}
                    />
                ) : !isWelcomed(sessionData) ? (
                    <Welcome />
                ) : (
                    <>
                        <CSSTransition
                            in={showMenu}
                            timeout={menu && menu.type === MENU_TYPES.FIXED ? 0 : ANIMATION_TIMEOUT}
                            classNames={"bottomAnimation"} // fadeInAnimation , topAnimation, leftAnimation
                            unmountOnExit
                        >
                            <Menu />
                        </CSSTransition>
                        {landingPage && (
                            <div
                                className={`${STB.model} absolute h-full w-full top-0 bg-cover bg-no-repeat ${
                                    showMenu && isHiddenMenu ? "blur" : null
                                }`}
                            >
                                <Content />
                                <PopupError />
                                <TickerTapeAndBanners />
                            </div>
                        )}
                    </>
                )
            ) : serverOnline ? (
                <>
                    <Installation />
                </>
            ) : (
                <ConnectionError />
            )}
            <Limbo global={true} />
            <Popups />
        </div>
    );
};

export default AppContent;
