import { useEffect, useState } from "preact/hooks";
import focus from "../../utils/focus";
import { getGridItemFocusedData } from "../../utils/gridUtils";
import { MOVIES } from "../../utils/constants";
import { figmapx2vh, figmapx2vw } from "../../utils/utils";
import ReactHtmlParser from "react-html-parser";

/**
 *
 * @param {name} string of attribute where to get name/title of item
 * @returns
 */
const InfoOfFocus = ({ activeCategory, name, description }) => {
    const [_name, setName] = useState();
    const [_description, setDescription] = useState();

    useEffect(() => {
        const gridItemFocused = getGridItemFocusedData(activeCategory);
        if (gridItemFocused) {
            setName(gridItemFocused[name]);
            setDescription(gridItemFocused[description]);
        }
    }, [focus.value.current]);

    return (
        <div
            id="infoOfFocus"
            style={{
                marginLeft: figmapx2vw(72),
                marginTop: figmapx2vh(130),
                minHeight: figmapx2vh(155),
                width: figmapx2vw(1368),
            }}
        >
            <div
                id="movieNameInfoOfFocus"
                className={"text-gray-100 font-700 text-ellipsis overflow-hidden nowrap"}
                style={{ fontSize: figmapx2vw(36) }}
            >
                {ReactHtmlParser(`${_name}`)}
            </div>
            <div
                id="moviePlotInfoOfFocus"
                className={"font-400 pt-6"}
                style={{
                    height: figmapx2vh(130),
                    width: figmapx2vw(1368),
                    fontSize: figmapx2vw(28),
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    "-webkit-line-clamp": 3,
                    "-webkit-box-orient": "vertical",
                }}
            >
                {ReactHtmlParser(`${_description}`)}
            </div>
        </div>
    );
};

export default InfoOfFocus;
