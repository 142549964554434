import { useEffect, useRef, useState } from "preact/hooks";
import { getTickerTapes } from "../../hooks/GraphqlCalls/TickerTapesAndBanners/getTickerTapes";
import {
    isActiveSequence,
    orderSignageByActiveLevelAndId,
    ScheduleDailyType,
    ScheduleDaysType,
} from "../../utils/signageUtils";
import {
    checkPriorityOfItems,
    TICKER_TAPE,
    TickerTape_Banner_Size,
    TickerTape_Speed,
    X_Axis,
    Y_Axis,
} from "../../utils/tickerTapeAndBannerUtils";
import Banner from "./Banner";
import TickerTape from "./TickerTape";
import { MS_INTERVAL } from "../../utils/constants";
import { getBanners } from "../../hooks/GraphqlCalls/TickerTapesAndBanners/getBanners";
import { useDispatch, useSelector } from "react-redux";
import { setRenewBanners, setRenewTickertapes } from "../../actions/uiActions";
import { SESSION } from "../../utils/session";

const TickerTapeAndBanners = () => {
    const dispatch = useDispatch();

    // TODO: si llega un mensaje [importante, prioritario, critico] cerrar banners y mostrar el mensaje -DONE (bannerOnScreen)-
    // Si hay un mensaje o una alarma activa en pantalla no sale el BANNER.

    const { renewTickertapes, renewBanners } = useSelector((state) => state.ui);

    const [tickerTapes, setTickerTapes] = useState(null);
    const [banners, setBanners] = useState(null);

    useEffect(() => {
        //get tickerTapes
        SESSION.tickertapesEnabled() &&
            getTickerTapes((_tickerTapes) => {
                setTickerTapes(checkPriorityOfItems(_tickerTapes));
            });

        //get banners
        SESSION.bannersEnabled() &&
            getBanners((_banners) => {
                setBanners(checkPriorityOfItems(_banners));
            });
    }, []);

    useEffect(() => {
        renewTickertapes &&
            SESSION.tickertapesEnabled() &&
            getTickerTapes((_tickerTapes) => {
                setTickerTapes(checkPriorityOfItems(_tickerTapes));
            });
        dispatch(setRenewTickertapes(false));
    }, [renewTickertapes]);

    useEffect(() => {
        renewBanners &&
            SESSION.bannersEnabled() &&
            getBanners((_banners) => {
                setBanners(checkPriorityOfItems(_banners));
            });
        dispatch(setRenewBanners(false));
    }, [renewBanners]);

    return (
        <>
            {tickerTapes && <TickerTape tickerTapes={tickerTapes} />}
            {banners && <Banner banners={banners} />}
        </>
    );
};

export default TickerTapeAndBanners;
